.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid orange;
    border-radius: 20%;
    margin: -10px 0 ;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #D9D9D9;
}

::placeholder{
    color: #D9D9D9;
}

.btn-j{
    background-color: orange;
    color: white;
}
.footer-conatiner{
    position: relative;
}

.footer-conatiner>hr{
    border: 1px solid #D9D9D9;
}

.footer{
    padding: 1rem 2rem;
    flex-direction: column;
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social{
    display: flex;
    gap: 4rem;
}

.social>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}
